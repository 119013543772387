<template>
  <div class="cccx-pagination">
    <span
      v-for="(item, index) in total"
      :key="index"
      class="cccx-pagination_bullet"
      :class="{
        ['cccx-pagination_bullet-active']: index === activeIndex,
      }"
    ></span>
  </div>
</template>
<script>
export default {
  name: 'CccxPagination',
  props: {
    total: {
      type: Number,
      default: 0,
    },
    activeIndex: {
      type: Number,
      default: 0,
    },
  },
}
</script>
<style lang="less" scoped>
.cccx-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  .cccx-pagination_bullet {
    width: 6px;
    height: 6px;
    background: rgba(255, 255, 255, 0.4);
    vertical-align: middle;
    border: 1px solid rgba(0, 0, 0, 0.4);
    display: inline-block;
    border-radius: 50%;
    margin: 0 4px;
  }
  .cccx-pagination_bullet-active {
    width: 7px;
    height: 7px;
    background: rgba(34, 34, 34, 0.8);
    border: 1px solid rgba(255, 255, 255, 0.8);
  }
}
</style>
