var render = function render(){var _vm=this,_c=_vm._self._c;return _c('swiper-container',{ref:"swiper",staticClass:"sideslip-marquee_swiper",attrs:{"destroy-on-disconnected":"false","init":"false","space-between":_vm.intervalSpacing ? 8 : 0,"slides-per-view":4.32,"slides-per-group":_vm.slides * 4,"free-mode":true,"free-mode-sticky":false,"free-mode-momentum-bounce":false,"free-mode-momentum":false,"free-mode-momentum-ratio":0.3,"resistance-ratio":0.4,"loop":false,"autoplay-delay":5,"autoplay-stop-on-last-slide":true,"autoplay-disable-on-interaction":true,"speed":(_vm.slides -1 ) * 4 * 2000},on:{"swiperslidermove":_vm.handleSliderMove}},_vm._l((_vm.colsItemList),function(cols,_i){return _c('swiper-slide',{key:_i,staticClass:"sideslip-marquee_swiper-slide",class:{
      'slide-intervalSpacing': _vm.intervalSpacing,
    }},_vm._l((cols),function(item,i){return _c('div',{key:i,on:{"click":function($event){return _vm.clickItem(item, i)}}},[(item.image)?_c('BaseImg',{directives:[{name:"tap",rawName:"v-tap",value:(
          _vm.getAnalysisData('2-22-2', {
            item,
            index: i,
            act_nm: _vm.isAutoPlayExpose ? 'auto_move' : 'auto_stop',
          })
        ),expression:"\n          getAnalysisData('2-22-2', {\n            item,\n            index: i,\n            act_nm: isAutoPlayExpose ? 'auto_move' : 'auto_stop',\n          })\n        "},{name:"expose",rawName:"v-expose",value:(
          _vm.getAnalysisData('2-22-1', {
            item,
            index: i,
            act_nm: _vm.isAutoPlayExpose ? 'auto_move' : 'auto_stop',
          })
        ),expression:"\n          getAnalysisData('2-22-1', {\n            item,\n            index: i,\n            act_nm: isAutoPlayExpose ? 'auto_move' : 'auto_stop',\n          })\n        "}],class:{ 'fsp-element': _i < 1 },attrs:{"brand":_vm.brand,"placeholder":{
          width: item.image.width,
          height: item.image.height,
        },"ratio":item.image.ratio,"img-src":item.image.src,"img-design-width":160,"first-screen":_vm.isAutoSlide ? true : _vm.propData?.isFirstPage && _i < 5,"ada":item.ada},on:{"baseImageMounted":function($event){return _vm.baseImageMounted()}}}):_vm._e(),_vm._v(" "),(item.smallTitle)?_c('div',{class:{
          ['sideslip-marquee__title']: true,
        }},[_vm._v("\n        "+_vm._s(item.smallTitle)+"\n      ")]):_vm._e()],1)}),0)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }