<template>
  <div
    :class="[
      'sideslip-image-static',
      `sideslip-image-${ isSlider ? 'slider' : 'swiper' }`,
      intervalSpacing ? 'has-item-space' : '',
      !isSlider ? 'no-outside-padding' : '',
    ]"
  >
    <!-- 横滑2.5、3.5、4.5 -->
    <template v-if="isSlider">
      <template v-for="(item, i) in data">
        <BaseImg
          v-if="item.image"
          :key="i"
          v-tap="getAnalysisData('2-22-2', { item, index: i, useBffApi })"
          v-expose="getAnalysisData('2-22-1', { item, index: i, useBffApi })"
          :class="[`sideslip-image-slider__item-${type}`]"
          :brand="brand"
          :placeholder="{
            width: item.image.width,
            height: item.image.height,
          }"
          :ratio="item.image.ratio"
          :img-src="item.image.src"
          :img-design-width="imgDesignWidth"
          :ada="item.ada"
          :first-screen="isFirstPage && i < 5"
          :comp-src="compSrc"
          @click="(event) => clickItem(event, item, i)"
          @baseImageMounted=" baseImageMounted()"
        />
      </template>
    </template>
    <template v-else>
      <!-- 横滑2、3、4、5 -->
      <div
        v-for="(item, i) in data"
        :key="i"
        :class="[`sideslip-image-swiper__item-${type}`, lastLine(i), currentPageIndex < 1 ? 'fsp-element' : '']"
      >
        <!--item_loc: `a_b_c` 表示第几屏、第几行、第几个坑位-->
        <BaseImg
          v-if="item.image"
          v-tap="
            getAnalysisData('2-22-2', {
              item: calcCateItem(item, currentPageIndex, i),
              tab: reportTab(i),
              'act_nm': reportTab(i).tabText ? 'click_pic' : '-',
              index: i,
            })
          "
          v-expose="
            getAnalysisData('2-22-1', {
              item: calcCateItem(item, currentPageIndex, i),
              tab: reportTab(i),
              index: i,
            })
          "
          :brand="brand"
          :placeholder="{
            width: item.image.width,
            height: item.image.height,
          }"
          :ratio="item.image.ratio"
          :img-src="item.image.src"
          :img-design-width="imgDesignWidth"
          :ada="item.ada"
          :first-screen="isFirstPage && currentPageIndex < 1"
          :comp-src="compSrc"
          @click="(event) => clickItem(event, calcCateItem(item, currentPageIndex, i), i)"
          @baseImageMounted=" baseImageMounted()"
        />
        <div
          v-if="item.smallTitle"
          class="sideslip-image__title"
          :class="{ 'sideslip-five': type === 'five' }"
        >
          {{ item.smallTitle }}
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import BaseImg from '../../base/BaseImg'
import mixin from '../mixins/mixin'

// 根据横滑类型映射图片样式类型
const COMPONENT_TYPE = {
  // slider
  ITEM_IMAGE_CAROUSEL_TWO_POINT_FIVE: 'two',
  ITEM_IMAGE_CAROUSEL_THREE_POINT_FIVE: 'three',
  ITEM_IMAGE_CAROUSEL_FOUR_POINT_FIVE: 'four',
  // swiper
  ITEM_IMAGE_FOUR_COLS_CAROUSEL: 'four',
  ITEM_IMAGE_FIVE_COLS_CAROUSEL: 'five',
  ITEM_IMAGE_TWO_COLS_CAROUSEL: 'two',
  ITEM_IMAGE_THREE_COLS_CAROUSEL: 'three',
  // dynamic
  MULTI_ITEMS_CAROUSEL_TABS_DYNAMIC: 'four',
  MULTI_ITEMS_CAROUSEL_IND_DYNAMIC: 'four',
  MULTI_ITEMS_CAROUSEL_CON_DYNAMIC: 'four',
  MULTI_ITEMS_CAROUSEL_THREE_POINT_FIVE_DYNAMIC: 'three',
}

// 根据横滑类型映射图片裁切尺寸
const DESIGN_WIDTH_TYPE = {
  [COMPONENT_TYPE.ITEM_IMAGE_CAROUSEL_TWO_POINT_FIVE]: 288,
  [COMPONENT_TYPE.ITEM_IMAGE_CAROUSEL_THREE_POINT_FIVE]: 208,
  [COMPONENT_TYPE.ITEM_IMAGE_CAROUSEL_FOUR_POINT_FIVE]: 160,
  [COMPONENT_TYPE.ITEM_IMAGE_TWO_COLS_CAROUSEL]: 260,
  [COMPONENT_TYPE.ITEM_IMAGE_THREE_COLS_CAROUSEL]: 216,
  [COMPONENT_TYPE.ITEM_IMAGE_FOUR_COLS_CAROUSEL]: 180,
  [COMPONENT_TYPE.ITEM_IMAGE_FIVE_COLS_CAROUSEL]: 150,
}

export default {
  name: 'SideSlipImage',
  components: { BaseImg },
  mixins: [mixin],
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    isSlider: {
      type: Boolean,
      default: true,
    },
    // 组件的位置
    index: {
      type: Number,
      default: 0,
    },
    propData: {
      type: Object,
      default: () => ({}),
    },
    sceneData: {
      type: Object,
      default: () => ({}),
    },
    cateLinks: {
      type: Object,
      default: () => ({}),
    },
    tabText: {
      type: [String, undefined],
      default: '',
    },
    brand: {
      type: String,
      default: 'shein',
    },
    currentPageIndex: {
      type: Number,
      default: 0,
    },
    compSrc: {
      type: String,
      default: ''
    }
  },
  data() {
    const { styleType = '', props: { metaData: { intervalSpacing = false } } } = this.propData || {}
    return {
      type: COMPONENT_TYPE[styleType] || '',
      intervalSpacing: Number(intervalSpacing), // 组件间隙留白
    }
  },
  computed: {
    isTopBanner() {
      return this.sceneData?.pageType === 'topBanner'
    },
    useBffApi() {
      return this.isTopBanner
    },
    imgDesignWidth() {
      return DESIGN_WIDTH_TYPE[this.type] || 0
    },
    isFirstPage() {
      return this.propData?.isFirstPage
    },
  },
  methods: {
    baseImageMounted() {
      this.$emit('baseImageMounted')
    },
    clickItem(event, item, i) {
      // 阻止默认行为导致触发body scroll
      event.preventDefault()

      // from mixin
      const url = this.cccLink.getFullLink({
        item,
        cateLinks: this.cateLinks,
        compIndex: this.index,
        index: i,
      })

      this.jumpLink({ url, hrefType: item.hrefType })
    },
    calcCateItem(item, itemsIndex, index) {
      // 计算item的位置,itemsIndex第几屏、chunkIndex第几个坑位
      const itemLoc = `${itemsIndex + 1}_${index + 1}`
      return { item_loc: itemLoc, ...item }
    },
    lastLine(i) {
      const lastNum = this.type === 'four' ? 4 : this.type === 'five' ? 5 : 0
      return this.data.length - i <= lastNum ? 'last-line' : ''
    },
    reportTab(i) {
      return {
        tabText: this.tabText,
        index: i
      }
    }
  },
}
</script>

<style lang="less" scoped>
@vw: 375/100vw;
.sideslip-image-slider {
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  line-height: 18px;
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
    display: none;
  }

  &__item-two {
    position: relative;
    display: inline-block;
    width: 144 / @vw;
    vertical-align: top;
  }

  &__item-three {
    position: relative;
    display: inline-block;
    width: 104 / @vw;
    vertical-align: top;
  }

  &__item-four {
    position: relative;
    display: inline-block;
    width: 80 / @vw;
    vertical-align: top;
  }

  .margin-right:not(:last-child) {
    margin-right: 8 / @vw;
  }
}

.has-item-space {
  padding: 0 12 / @vw;
  .sideslip-image-slider__item-two,
  .sideslip-image-slider__item-three,
  .sideslip-image-slider__item-four {
    margin-right: 8 / @vw;
    &:last-child{
      margin-right: 0
    }
  }

  .sideslip-image-slider__item-two {
    width: 138 / @vw;
  }

  .sideslip-image-slider__item-three {
    width: 96 / @vw;
  }

  .sideslip-image-slider__item-four {
    width: 80 / @vw;
  }

  .sideslip-image-swiper {
    margin-bottom: -0.2133rem;
  }

  .sideslip-image-swiper__item-two,
  .sideslip-image-swiper__item-three,
  .sideslip-image-swiper__item-four,
  .sideslip-image-swiper__item-five {
    margin-bottom: 0.2133rem;
  }

  .last-line {
    margin-bottom: 0;
  }
}

.sideslip-image-swiper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &__item-two {
    width: calc(~"100% / 2");
  }

  &__item-three {
    width: calc(~"100% / 3");
  }

  &__item-four {
    width: calc(~"100% / 4");
  }

  &__item-five {
    width: calc(~"100% / 5");
  }

  .sideslip-five {
    transform: scale(0.92);
    transform-origin: left top;
  }

  .sideslip-image__title {
    font-family: Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    word-wrap: break-word;
    text-align: center;
    margin: 4 / @vw;
    .line-camp();
  }
}

.has-item-space {
  // 横滑2、3图无边距
  &.no-outside-padding{
    padding: 0;
  }
  .sideslip-image-swiper__item-two {
    width: calc(~"(100% - 0.213333rem) / 2");
  }

  .sideslip-image-swiper__item-three {
    width: calc(~"(100% - 0.42666rem) / 3");
  }

  .sideslip-image-swiper__item-four {
    width: calc(~"(100% - 0.64rem) / 4");
  }

  .sideslip-image-swiper__item-five {
    width: calc(~"(100% - 0.85333rem) / 5");
  }
}
</style>
