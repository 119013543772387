<template>
  <div
    class="ccc-3d-left"
    :style="{
      backgroundColor,
    }"
  >
    <div class="swiper-content">
      <div
        class="swiper-background"
        :style="activityBgStyle"
      ></div>
      <div class="swiper-overflow-hidden">
        <swiper-container
          ref="swiper"
          init="false"
          dir="ltr"
          :class="`carousel-3d-left-${compId} carousel-3d_left-swiper`"
          @click="clickSlide"
        >
          <swiper-slide
            v-for="(item, i) in [...items, ...items]"
            :key="i"
            v-expose="getAnalysisData('2-22-1', { item, index: i})"
            class="swiper-slide not-fsp-element"
            :style="customSlideStyle(i)"
          >
            <BaseImg
              v-if="item.coverImage && item.coverImage.src"
              class="cover"
              :img-src="item.coverImage.src"
              :img-design-width="820"
              :first-screen="propData.firstScreen && i < 3"
            />
            <BaseImg
              class="image"
              :img-src="item.image.src"
              :img-design-width="820"
              :first-screen="propData.firstScreen && i < 3"
            />
            <div
              v-if="item.imageText"
              class="title-block"
            >
              <div class="title-content">
                <p class="text">
                  {{ item.imageText }}
                </p>
                <sui_icon_more_right_12px
                  name="sui_icon_more_right_12px"
                  size="12px"
                  color="#fff"
                  class="more-right-icon"
                  :is-rotate="GB_cssRight"
                />
              </div>
            </div>
          </swiper-slide>
        </swiper-container>
        <CccxPagination
          :total="items.length"
          :active-index="activeIndex"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { commonProps } from 'public/src/pages/components/ccc/common/common-props.js'
import mixins from 'public/src/pages/components/ccc/components/mixins/mixin.js'
import BaseImg from 'public/src/pages/components/ccc/base/BaseImg.vue'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { handleColor } from 'public/src/pages/components/ccc/common/utils.js'
import { sui_icon_more_right_12px } from '@shein-aidc/icon-vue2'
import { handleAutoPlay } from 'public/src/pages/components/ccc/common/utils.js'
import { Autoplay, EffectCreative } from 'swiper/modules'
import CccxPagination from 'public/src/pages/components/ccc/base/Pagination.vue'
import { register } from 'swiper/element'
typeof window !== 'undefined' && register()
const { PUBLIC_CDN = '' } = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}

const BACKGROUND_TYPE = {
  CUSTOMIZE: 'customize',
  BLUR: 'blur'
}

export default {
  name: 'CccSideslip3DLeft',
  components: {
    CccxPagination,
    sui_icon_more_right_12px,
    BaseImg
  },
  mixins: [mixins],
  props: commonProps,
  inject: {
    cutImg: {
      from: 'cutImg',
      default: url => url,
    },
  },
  data() {
    const { id: compId, props = {} } = this.propData
    const { items = [], metaData = {} } = props || {}
    const {
      backgroundColor = '#fff',
      backgroundType = BACKGROUND_TYPE.BLUR,
      time = 3
    } = metaData || {}
    const { GB_cssRight = false } = this.context

    return {
      // props
      compId,
      items,
      backgroundColor:
        backgroundType === BACKGROUND_TYPE.BLUR ? '#fff' : handleColor(backgroundColor),
      backgroundType,
      GB_cssRight,
      time,
      // status
      activeIndex: 0,
    }
  },
  computed: {
    activityBgStyle() {
      if (this.backgroundType === BACKGROUND_TYPE.BLUR) {
        const imgSrc = this.items[this.activeIndex]?.image?.src || ''
        return { backgroundImage: `url('${imgSrc}')` }
      }
      return {}
    }
  },
  mounted() {
    this.initSwiper()
  },
  activated() {
    this.initSwiper()
  },
  destroyed() {
    this.observer = null
  },
  methods: {
    customSlideStyle(i) {
      const zIndex = this.items?.length * 2 - i
      let calcOne = i === 0 ? '0px' : `${27 * i}% - ${7.9467 * i}rem`
      let calcTwo = i === 0 ? '0px' : `-${400 * (i > 2 ? 2 : i)}px`
      if (this.GB_cssRight) {
        calcOne = i === 0 ? '17%' : `-${10 * (i === 2 ? 3 : i)}% - ${7.9467 * i}rem`
        calcTwo = i === 0 ? '-1.8933rem' : `-${400 * (i > 2 ? 2 : i)}px`
      }
      const scale = i === 0 ? 1 : (i > 1 ? 0.4 : 0.7)
      return {
        zIndex,
        transform: `translate3d(calc(${calcOne}), calc(0px), calc(${calcTwo})) rotateX(0deg) rotateY(0deg) rotateZ(0deg) scale(${scale})`,
        opacity: 1,
        flexShrink: 0,
      }
    },
    initSwiper(){
      const swiperEl = this.$refs.swiper
      const swiperParams = {
        slidesPerView: 'auto',
        centeredSlides: false,
        loop: true,
        speed: 1000,
        autoplay: {
          delay: this.time * 1000,
          disableOnInteraction: false,
        },
        effect: 'creative',
        loopAdditionalSlides: 2,
        creativeEffect: {
          limitProgress: 2,
          perspective: false,
          next: {
            translate: this.GB_cssRight ? ['-30%', 0, -400] : ['27%', 0, -400],
            scale: 0.7,
          },
          prev: {
            translate: this.GB_cssRight ? ['-3%', 0, -400] : ['-20%', 0, -400],
            scale: 0.7,
          },
        },
        on: {
          slideChange: swiper => {
            this.activeIndex = swiper.realIndex % this.items.length
          },
        },
        modules: [Autoplay, EffectCreative],
        injectStylesUrls: [
          PUBLIC_CDN + '/pwa_dist/libs/swiper/modules/effect-creative-element.min.css',
        ],
        injectStyles: this.GB_cssRight ? [
          `
            .swiper-wrapper{
              left: 18%
            }
          `,
        ] : [],
      }
      Object.assign(swiperEl, swiperParams)
      swiperEl.initialize()
      this.observer = handleAutoPlay(this.$refs.swiper)
      this.GB_cssRight && swiperEl.swiper.changeLanguageDirection('rtl')
    },
    clickSlide(e) {
      // 处理swiper为loop时 事件绑定的问题
      e?.stopPropagation?.()
      let itemIndex = this.activeIndex
      const item = this.items?.[itemIndex] || {}

      daEventCenter.triggerNotice({
        daId: '2-22-2',
        bindData: {
          sceneData: this.sceneData,
          propData: this.propData,
          compIndex: this.index,
          item,
          index: itemIndex,
        },
      })
      
      const url = this.cccLink.getFullLink({
        item,
        cateLinks: this.cateLinks,
        compIndex: this.index,
        index: itemIndex,
      })

      this.jumpLink({ url, hrefType: item.hrefType })
    },

    getCutImg(imgUrl) {
      if (!this?.cutImg) {
        return imgUrl.replace(/^https?\:/, '').replace(/\b(\.jpg|\.gif|\.png)\b/, '.webp')
      }
      return this.cutImg(imgUrl, 0, {})
    },
  },
}
</script>

<style lang="less">
@vw: 375/100vw;
@paddingSize: 12 / @vw;

.ccc-3d-left {
  width: 100%;
  margin: auto;
  position: relative;
  overflow: hidden;

  .swiper-content {
    position: relative;
    overflow: hidden;

    .swiper-background {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      min-height: 100%;
      transition: linear 1s;
      filter: blur(28px);
      background-size: 100% 100%;
    }

    .swiper-overflow-hidden {
      padding: 20 / @vw 12 / @vw 18 / @vw;
      overflow: hidden;
    }
  }

  .swiper-container {
    overflow: visible;
  }
  .carousel-3d_left-swiper{
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
  }
  .swiper-slide {
    width: 85%;
    transition-property: transform, opacity;
    position: relative;
    overflow: hidden;
    object-fit: cover;

    .cover {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      visibility: visible;
    }

    .image,
    .cover {
      width: 100%;
      display: block;
    }
  }

  .title-block {
    display: none;
  }

  .title-content {
    position: absolute;
    bottom: 0;
    z-index: @zindex-hack;
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    min-height: 28 / @vw;
    padding: 8 / @vw 4 / @vw;
    background-color: rgba(0, 0, 0, 0.4);

    .text {
      max-width: 200 / @vw;
      color: #fff;
      font-size: 12 / @vw;
      line-height: 14 / @vw;
      font-weight: 400;
      word-break: break-word;
      overflow-wrap: break-word;
      .line-camp(2);
    }
  }

  .swiper-slide-active {
    .title-block {
      display: block;
    }

    .cover {
      visibility: hidden;
    }
  }

  .swiper-slide-next {
    box-shadow: 0 12px 18px rgba(0, 0, 0, 0.16);
  }
}
</style>
