var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'sideslip-image-static',
    `sideslip-image-${ _vm.isSlider ? 'slider' : 'swiper' }`,
    _vm.intervalSpacing ? 'has-item-space' : '',
    !_vm.isSlider ? 'no-outside-padding' : '',
  ]},[(_vm.isSlider)?[_vm._l((_vm.data),function(item,i){return [(item.image)?_c('BaseImg',{directives:[{name:"tap",rawName:"v-tap",value:(_vm.getAnalysisData('2-22-2', { item, index: i, useBffApi: _vm.useBffApi })),expression:"getAnalysisData('2-22-2', { item, index: i, useBffApi })"},{name:"expose",rawName:"v-expose",value:(_vm.getAnalysisData('2-22-1', { item, index: i, useBffApi: _vm.useBffApi })),expression:"getAnalysisData('2-22-1', { item, index: i, useBffApi })"}],key:i,class:[`sideslip-image-slider__item-${_vm.type}`],attrs:{"brand":_vm.brand,"placeholder":{
          width: item.image.width,
          height: item.image.height,
        },"ratio":item.image.ratio,"img-src":item.image.src,"img-design-width":_vm.imgDesignWidth,"ada":item.ada,"first-screen":_vm.isFirstPage && i < 5,"comp-src":_vm.compSrc},on:{"click":(event) => _vm.clickItem(event, item, i),"baseImageMounted":function($event){return _vm.baseImageMounted()}}}):_vm._e()]})]:_vm._l((_vm.data),function(item,i){return _c('div',{key:i,class:[`sideslip-image-swiper__item-${_vm.type}`, _vm.lastLine(i), _vm.currentPageIndex < 1 ? 'fsp-element' : '']},[(item.image)?_c('BaseImg',{directives:[{name:"tap",rawName:"v-tap",value:(
          _vm.getAnalysisData('2-22-2', {
            item: _vm.calcCateItem(item, _vm.currentPageIndex, i),
            tab: _vm.reportTab(i),
            'act_nm': _vm.reportTab(i).tabText ? 'click_pic' : '-',
            index: i,
          })
        ),expression:"\n          getAnalysisData('2-22-2', {\n            item: calcCateItem(item, currentPageIndex, i),\n            tab: reportTab(i),\n            'act_nm': reportTab(i).tabText ? 'click_pic' : '-',\n            index: i,\n          })\n        "},{name:"expose",rawName:"v-expose",value:(
          _vm.getAnalysisData('2-22-1', {
            item: _vm.calcCateItem(item, _vm.currentPageIndex, i),
            tab: _vm.reportTab(i),
            index: i,
          })
        ),expression:"\n          getAnalysisData('2-22-1', {\n            item: calcCateItem(item, currentPageIndex, i),\n            tab: reportTab(i),\n            index: i,\n          })\n        "}],attrs:{"brand":_vm.brand,"placeholder":{
          width: item.image.width,
          height: item.image.height,
        },"ratio":item.image.ratio,"img-src":item.image.src,"img-design-width":_vm.imgDesignWidth,"ada":item.ada,"first-screen":_vm.isFirstPage && _vm.currentPageIndex < 1,"comp-src":_vm.compSrc},on:{"click":(event) => _vm.clickItem(event, _vm.calcCateItem(item, _vm.currentPageIndex, i), i),"baseImageMounted":function($event){return _vm.baseImageMounted()}}}):_vm._e(),_vm._v(" "),(item.smallTitle)?_c('div',{staticClass:"sideslip-image__title",class:{ 'sideslip-five': _vm.type === 'five' }},[_vm._v("\n        "+_vm._s(item.smallTitle)+"\n      ")]):_vm._e()],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }