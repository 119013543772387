<template>
  <div>
    <div class="carousel-image">
      <swiper-container
        ref="swiper"
        destroy-on-disconnected="false"
        class="carousel-image_swiper"
        slides-per-view="1"
        space-between="0"
        init="false"
        :autoplay-delay="time * 1000"
        :autoplay-disable-on-interaction="false"
        loop="true"
        :pagination="{
          hideOnClick: true,
        }"
        @click="handleSlideClick"
      >
        <swiper-slide
          v-for="(item, i) in carouselList"
          :key="i"
          class="carousel-image_swiper-slide"
        >
          <BaseImg
            ref="baseImageRef"
            v-tap="getAnalysisData('2-22-2', { item, index: i })"
            v-expose="getAnalysisData('2-22-1', { item, index: i })"
            class="base-img"
            fit="cover"
            :placeholder="{
              width: item.image.width,
              height: item.image.height,
            }"
            :style="bgStyle"
            :ratio="item.image.ratio"
            :img-src="item.image.src"
            img-design-width="950"
            :ada="item.ada"
            :alt="item.hrefTitle"
            :brand="brand"
            :comp-src="'carousel-image'"
            :first-screen="isFirstPage && i < 1"
          />
        </swiper-slide>
        <div
          slot="pagination"
          :class="`j-ccc-swiper-pagination-${index}`"
        ></div>
        <!-- 蒙层效果 -->
        <div
          v-if="showOverlayImage"
          slot="container-end"
          class="carousel-image__mask"
        >
          <BaseImg
            :placeholder="{
              width: overlayImage.width,
              height: overlayImage.height,
            }"
            :ratio="overlayImage.ratio"
            :img-src="overlayImage.src"
            img-design-width="950"
          />
        </div>
      </swiper-container>
    </div>
  </div>
</template>
<script>
import BaseImg from '../base/BaseImg'
import { commonProps } from 'public/src/pages/components/ccc/common/common-props.js'
import { handleAutoPlay } from 'public/src/pages/components/ccc/common/utils.js'
import mixins from './mixins/mixin'
import { register } from 'swiper/element'
import { Pagination, Autoplay } from 'swiper/modules'
typeof window !== 'undefined' && register()
const { PUBLIC_CDN = '' } = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}

export default {
  name: 'CarouselImage',
  components: {
    BaseImg
  },
  mixins: [mixins],
  props: {
    ...commonProps,
    cardStyle: {
      type: Object,
      default: () => ({}),
    },
    swiperInjectStyle: {
      type: String,
      default: '',
    }
  },
  data() {
    const { props = {} } = this.propData || {}
    const {
      items = [],
      metaData: { carouselWay = 'order', time = 3, showOverlayImage = false, overlayImage = {} },
    } = props
    return {
      time,
      items,
      carouselWay, // 轮播方式
      // 初始配置
      loadImg: {},
      // 是否展示蒙层效果
      showOverlayImage,
      overlayImage,
    }
  },
  computed: {
    carouselList() {
      let temp = this.items || []
      if (this.carouselWay === 'optimization') {
        const totalWeights = temp.reduce((acc, cur) => acc + Number(cur.weights || 0), 0)
        temp.forEach(i => {
          i.weights = i.weights / totalWeights + Math.random()
        })
        temp.sort((a, b) => b.weights - a.weights)
      } else if (this.carouselWay === 'random') {
        temp.sort(() => Math.round(Math.random()) * 2 - 1)
      }
      return temp || []
    },
    isFirstPage() {
      return this.propData.isFirstPage
    },
    bgStyle() {
      return {
        background: '#F2F2F2',
        borderRadius: this.cardStyle.borderRadius || '0px',
      }
    },
  },
  async mounted() {
    const swiperEl = this.$refs.swiper
    const swiperParams = {
      modules: [Pagination, Autoplay],
      injectStylesUrls: [ PUBLIC_CDN + '/pwa_dist/libs/swiper/modules/pagination-element.min.css'],
      injectStyles: [
        `
        .swiper-pagination{
          position: absolute;
        }
        .swiper-pagination .swiper-pagination-bullet-active{
          background: rgba(34,34,34,.8);
          border: 1px solid hsla(0,0%,100%,.8);
        }
        .swiper-pagination .swiper-pagination-bullet{
          width: 6px;
          height: 6px;
        }
        ${this.swiperInjectStyle}
      `,
      ],
    }
    Object.assign(swiperEl, swiperParams)
    swiperEl.initialize()
    this.handleAutoPlay()
  },
  deactivated() {
    this.$refs.swiper.swiper?.autoplay?.pause?.()
  },
  methods: {
    // 处理swiper为loop时 事件绑定的问题
    handleSlideClick() {
      let realIndex = this.$refs.swiper?.swiper?.realIndex || 0
      let item = this.carouselList[realIndex]
      const url = this.cccLink.getFullLink({
        item,
        cateLinks: this.cateLinks,
        compIndex: this.index,
        index: realIndex,
      })
      this.jumpLink({ url, hrefType: item.hrefType })
    },    
    handleAutoPlay() {
      const topHide = this.sceneData.pageFrom === 'homePage' ? 88 : 44
      const bottomHide = this.sceneData.pageFrom === 'homePage' ? 47 : 0
      this.observer = handleAutoPlay(this.$refs.swiper, {
        mask: [topHide, '0', bottomHide, '0'],
      })
    },
  },
}
</script>
<style lang="less">
.carousel-image {
  width: 100%;
  overflow: hidden;
  margin-bottom: -1px;
  /* 设置 Swiper 容器样式 */
  .carousel-image_swiper {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
  }
  /* 设置滑块 slide 样式 */
  .carousel-image_swiper-slide {
    --slides-per-view: 1;
    width: calc(100% / var(--slides-per-view));
    flex-shrink: 0;

  }

  .base-img{
    width: 100%;
  }
  &__mask {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    // 保证蒙层在swiper-pagination之上
    z-index: 11; /* stylelint-disable-line */
  }
}

</style>
