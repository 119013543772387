var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"carousel-image"},[_c('swiper-container',{ref:"swiper",staticClass:"carousel-image_swiper",attrs:{"destroy-on-disconnected":"false","slides-per-view":"1","space-between":"0","init":"false","autoplay-delay":_vm.time * 1000,"autoplay-disable-on-interaction":false,"loop":"true","pagination":{
        hideOnClick: true,
      }},on:{"click":_vm.handleSlideClick}},[_vm._l((_vm.carouselList),function(item,i){return _c('swiper-slide',{key:i,staticClass:"carousel-image_swiper-slide"},[_c('BaseImg',{directives:[{name:"tap",rawName:"v-tap",value:(_vm.getAnalysisData('2-22-2', { item, index: i })),expression:"getAnalysisData('2-22-2', { item, index: i })"},{name:"expose",rawName:"v-expose",value:(_vm.getAnalysisData('2-22-1', { item, index: i })),expression:"getAnalysisData('2-22-1', { item, index: i })"}],ref:"baseImageRef",refInFor:true,staticClass:"base-img",style:(_vm.bgStyle),attrs:{"fit":"cover","placeholder":{
            width: item.image.width,
            height: item.image.height,
          },"ratio":item.image.ratio,"img-src":item.image.src,"img-design-width":"950","ada":item.ada,"alt":item.hrefTitle,"brand":_vm.brand,"comp-src":'carousel-image',"first-screen":_vm.isFirstPage && i < 1}})],1)}),_vm._v(" "),_c('div',{class:`j-ccc-swiper-pagination-${_vm.index}`,attrs:{"slot":"pagination"},slot:"pagination"}),_vm._v(" "),(_vm.showOverlayImage)?_c('div',{staticClass:"carousel-image__mask",attrs:{"slot":"container-end"},slot:"container-end"},[_c('BaseImg',{attrs:{"placeholder":{
            width: _vm.overlayImage.width,
            height: _vm.overlayImage.height,
          },"ratio":_vm.overlayImage.ratio,"img-src":_vm.overlayImage.src,"img-design-width":"950"}})],1):_vm._e()],2)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }