<template>
  <div
    class="ccc-3d-center"
    :style="{
      backgroundColor,
    }"
  >
    <div
      class="bg-image"
      :style="activityBgStyle"
    ></div>
    <div :style="customContainerStyle">
      <swiper-container
        ref="swiper"
        init="false"
        class="ccc-3d-center_swiper"
        :style="customWrapperStyle"
        @click="clickSlide"
      >
        <swiper-slide
          v-for="(item, i) in [...items, ...items]"
          :key="i"
          v-expose="getAnalysisData('2-22-1', { item, index: i })"         
          class="ccc-3d-center_swiper-slide not-fsp-element"
          :style="customSlideStyle(i)"
        >
          <BaseImg
            v-if="item.coverImage && item.coverImage.src"
            class="cover"
            :img-src="item.coverImage.src"
            :img-design-width="820"
            :first-screen="propData.firstScreen"
          />
          <BaseImg
            class="image"
            :img-src="item.image.src"
            :img-design-width="820"
            :first-screen="propData.firstScreen"
          />
          <div
            v-if="item.imageText"
            class="title-block"
          >
            <div class="title-content">
              <p class="text">
                {{ item.imageText }}
              </p>
              <sui_icon_more_right_12px
                size="12px"
                color="#fff"
                class="more-right-icon"
                :is-rotate="GB_cssRight"
              />
            </div>
          </div>
        </swiper-slide>
      </swiper-container>
      <CccxPagination
        :total="items.length"
        :active-index="activeIndex"
      />
    </div>
  </div>
</template>

<script>
import BaseImg from 'public/src/pages/components/ccc/base/BaseImg.vue'
import { commonProps } from 'public/src/pages/components/ccc/common/common-props.js'
import mixins from 'public/src/pages/components/ccc/components/mixins/mixin.js'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { handleColor } from 'public/src/pages/components/ccc/common/utils.js'
import { handleAutoPlay } from 'public/src/pages/components/ccc/common/utils.js'
import { sui_icon_more_right_12px } from '@shein-aidc/icon-vue2'
import { Autoplay, EffectCoverflow } from 'swiper/modules'
import CccxPagination from 'public/src/pages/components/ccc/base/Pagination.vue'
import { register } from 'swiper/element'
typeof window !== 'undefined' && register()

const BACKGROUND_TYPE = {
  CUSTOMIZE: 'customize',
  BLUR: 'blur'
}

export default {
  name: 'CccSideslip3DCenter',
  components: {
    sui_icon_more_right_12px,
    CccxPagination,
    BaseImg
  },
  mixins: [mixins],
  props: commonProps,
  inject: {
    cutImg: {
      from: 'cutImg',
      default: url => url
    }
  },
  data() {
    const { props = {} } = this.propData
    const { items = [], metaData = {} } = props || {}
    const {
      backgroundColor = '#fff',
      backgroundType = BACKGROUND_TYPE.BLUR,
      time = 3
    } = metaData || {}
    const GB_cssRight = this.context?.GB_cssRight || false
    return {
      // props
      items,
      backgroundColor:
        backgroundType === BACKGROUND_TYPE.BLUR ? '#fff' : handleColor(backgroundColor),
      backgroundType,
      time,

      // status
      swiperInstance: '',
      activeIndex: 0,
      GB_cssRight,
      isInitSwiper: false,
    }
  },
  computed: {
    activityBgStyle() {
      if (this.backgroundType === BACKGROUND_TYPE.BLUR) {
        const imgSrc = this.items[this.activeIndex]?.image?.src || ''
        return { backgroundImage: `url('${imgSrc}')` }
      }
      return {}
    },
    customWrapperStyle() {
      return this.isInitSwiper ? {} : {
        perspective: '1200px',
        transformStyle: 'preserve-3d',
        transform: `translate3d(${this.GB_cssRight ? '' : '-'}13.3333rem, 0px, 0px)`,
      }
    },
    customContainerStyle() {
      return {
        width: '100%',
        position: 'relative',
        overflow: 'hidden',
      }
    }
  },
  mounted() {
    this.initSwiper()
  },
  beforeDestroy() {
    // 视口曝光的销毁
    this.obInstance?.destroy?.()
    this.obInstance = null
  },
  activated() {
    this.initSwiper()
  },
  methods: {
    customSlideStyle(i) {
      const midNum = i - 2
      const zIndex = midNum > 0 ? -midNum : midNum
      const xValue = midNum < 0 ? -2.6667 * zIndex : 2.6667 * zIndex
      const translateX = i === zIndex ? '0px' : `${this.GB_cssRight ? -xValue : xValue}rem`
      const translateZ = i === zIndex ? '0px' : `${2.6667 * zIndex}rem`
      const scale = zIndex === 0 ? 1 : 1 + zIndex * 0.1
      return {
        zIndex,
        transform: `translate3d(${translateX}, 0px, ${translateZ}) rotateX(0deg) rotateY(0deg) scale(${scale});`,
        opacity: 1,
        flexShrink: 0,
      }
    },
    clickSlide(e) {
      // 处理swiper为loop时 事件绑定的问题
      e?.stopPropagation?.()
      let itemIndex = this.activeIndex
      
      const item = this.items?.[itemIndex] || {}

      daEventCenter.triggerNotice({
        daId: '2-22-2',
        bindData: {
          sceneData: this.sceneData,
          propData: this.propData,
          compIndex: this.index,
          item,
          index: itemIndex
        }
      })

      const url = this.cccLink.getFullLink({
        item,
        cateLinks: this.cateLinks,
        compIndex: this.index,
        index: itemIndex
      })

      this.jumpLink({ url, hrefType: item.hrefType })
    },
    initSwiper() {
      const swiperEl = this.$refs.swiper
      const swiperParams = {
        loop: true,
        effect: 'coverflow',
        initialSlide: 0,
        centeredSlides: true,
        observer: true,
        loopedSlides: this.items.length,
        watchSlidesProgress: true,
        watchSlidesVisibility: true,
        slidesPerView: 1.3,
        // TODO: 偶现3d效果不生效情况，临时在swiper-container节点上补上swiper-container-3d类名修复
        coverflowEffect: {
          rotate: 0, // slide做3d旋转时Y轴的旋转角度
          stretch: 100, // 每个slide之间的拉伸值，越大slide靠得越紧
          // depth: 200, // slide的位置深度。值越大z轴距离越远，看起来越小
          scale: 0.9, // slide的缩放比例
          slideShadows: false
        },
        autoplay: {
          delay: Number(this.time) * 1000,
          disableOnInteraction: false
        },
        on: {
          transitionStart: (swiper) => {
            this.activeIndex = swiper.realIndex % this.items.length
          },
        },
        modules: [Autoplay, EffectCoverflow],
      }
      Object.assign(swiperEl, swiperParams)
      swiperEl.initialize()
      this.$nextTick(() => {
        this.isInitSwiper = true
      })
      this.observer = handleAutoPlay(this.$refs.swiper)
    },
    getCutImg(imgUrl) {
      if (!this?.cutImg) {
        return imgUrl.replace(/^https?\:/, '').replace(/\b(\.jpg|\.gif|\.png)\b/, '.webp')
      }
      return this.cutImg(imgUrl, 0, {})
    },
  }
}
</script>

<style lang="less">
@vw: 375/100vw;

.ccc-3d-center {
  width: 100%;
  margin: auto;
  position: relative;
  overflow: hidden;
  padding: 20 / @vw 12 / @vw 18 / @vw;

  .bg-image {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    min-height: 100%;
    transition: linear 1s;
    filter: blur(28px);
    background-size: 100% 100%;
  }
  &_swiper{
    display: flex;
    flex-wrap: nowrap;
  }
  &_swiper-slide {
    width: calc(100% / 1.3);
    flex-shrink: 0;
    position: relative;
    overflow: hidden;
    object-fit: cover;

    .cover {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      visibility: visible;
    }

    .image,
    .cover {
      width: 100%;
      display: block;
    }
  }
  .swiper-slide-active {
    .title-block {
      display: block;
    }

    .cover {
      visibility: hidden;
    }
  }

  .title-block {
    display: none;
  }

  .title-content {
    position: absolute;
    bottom: 0;
    z-index: @zindex-hack;
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    min-height: 28 / @vw;
    padding: 8 / @vw 4 / @vw;
    background-color: rgba(0, 0, 0, 0.4);

    .text {
      max-width: 200 / @vw;
      color: #fff;
      font-size: 12 / @vw;
      line-height: 14 / @vw;
      font-weight: 400;
      word-break: break-word;
      overflow-wrap: break-word;
      .line-camp(2);
    }
  }

}
</style>
